import { FC } from 'react';
import { useAppSelector } from '../hooks/useAppSelector';
import { Navigate, Outlet } from 'react-router-dom';

const Auth: FC = () => {
  const { isAuth } = useAppSelector((state) => state.auth);

  if (!isAuth) {
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
};

export default Auth;

import React, { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../layout/Layout';
import ScreenLoader from '../components/ScreenLoader/ScreenLoader';
// import {
//   WelcomePage,
//   FormPage,
//   AdPage,
//   PlatformPage,
//   PaymentPage,
//   CongratsPage,
//   SignUpPage,
//   CreativesPage,
//   CampaignsPage,
// } from '../pages';

// import ModePickerPage from '../pages/ModePickerPage/ModePickerPage';

// import PrivacyPage from '../pages/PrivacyPage/PrivacyPage';
// import RefundPage from '../pages/RefundPage/RefundPage';

// import ProtectedRoute from '../hoc/ProtectedRoute';
import Auth from '../hoc/Auth';

const WelcomePage = lazy(() => import('../pages/WelcomePage/WelcomePage'));
const PlatformPage = lazy(() => import('../pages/PlatformPage/PlatformPage'));
const ModePickerPage = lazy(
  () => import('../pages/ModePickerPage/ModePickerPage')
);
const FormPage = lazy(() => import('../pages/FormPage/FormPage'));
const AdPage = lazy(() => import('../pages/AdPage/AdPage'));
const PaymentPage = lazy(() => import('../pages/PaymentPage/PaymentPage'));
const CongratsPage = lazy(() => import('../pages/CongratsPage/CongratsPage'));
const CreativesPage = lazy(
  () => import('../pages/CreativesPage/CreativesPage')
);
const CampaignsPage = lazy(
  () => import('../pages/CampaignsPage/CampaignsPage')
);
const SignInPage = lazy(() => import('../pages/SignInPage/SignInPage'));
const SignUpPage = lazy(() => import('../pages/SignUpPage/SignUpPage'));
const PrivacyPage = lazy(() => import('../pages/PrivacyPage/PrivacyPage'));
const RefundPage = lazy(() => import('../pages/RefundPage/RefundPage'));

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <Suspense fallback={<ScreenLoader />}>
            <WelcomePage />
          </Suspense>
        }
      />
      <Route element={<Auth />}>
        <Route path="/" element={<Layout />}>
          <Route
            path="generate"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <PlatformPage />
              </Suspense>
            }
          />
          <Route
            path="mode"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <ModePickerPage />
              </Suspense>
            }
          />
          <Route
            path="form"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <FormPage />
              </Suspense>
            }
          />
          <Route
            path="ad"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <AdPage />
              </Suspense>
            }
          />
          <Route
            path="payment"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <PaymentPage />
              </Suspense>
            }
          />
          <Route
            path="done"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <CongratsPage />
              </Suspense>
            }
          />
          <Route
            path="creatives"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <CreativesPage />
              </Suspense>
            }
          />
          <Route
            path="campaigns"
            element={
              <Suspense fallback={<ScreenLoader />}>
                <CampaignsPage />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route
        path="login"
        element={
          <Suspense fallback={<ScreenLoader />}>
            <SignInPage />
          </Suspense>
        }
      />
      <Route
        path="signUp"
        element={
          <Suspense fallback={<ScreenLoader />}>
            <SignUpPage />
          </Suspense>
        }
      />
      <Route path="/" element={<Layout />}>
        <Route
          path="privacy"
          element={
            <Suspense fallback={<ScreenLoader />}>
              <PrivacyPage />
            </Suspense>
          }
        />
        <Route
          path="refund"
          element={
            <Suspense fallback={<ScreenLoader />}>
              <RefundPage />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;

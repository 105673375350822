import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import $api from '../../../api/api';
import { ILoginResponse } from '../models/ILoginResponse';
import { IRegisterData } from '../models/IRegisterData';
import { ILoginData } from '../models/ILoginData';
import { IRegisterResponse } from '../models/IRegisterResponse';

interface IState {
  loading: boolean;
  error: string;
  isAuth: boolean;
  email: string;
  paymentDetails: {
    paymentLink: string;
    paymentPlan: string;
  };
}

const initialState = {
  loading: false,
  error: '',
  isAuth: false,
  email: '',
  paymentDetails: {
    paymentLink: '',
    paymentPlan: 'starter_month',
  },
};

export const handleRegister = createAsyncThunk<
  IRegisterResponse,
  IRegisterData
>('auth/register', async (data, { rejectWithValue }) => {
  try {
    const response = await $api.post('register', data);

    console.log(response);

    if (response.status !== 200) {
      console.log(response.status);
      throw new Error('Something went wrong');
    }

    return response.data;
  } catch (e: any) {
    console.log(e);
    console.log(e.response.statusText);

    if (e.response.data.errorMessage) {
      return rejectWithValue(e.response.data.errorMessage);
    }

    return rejectWithValue(e.response.statusText);
  }
});

export const handleLogin = createAsyncThunk<ILoginResponse, ILoginData>(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await $api.post('login', data);

      if (response.status !== 200) {
        console.log(response.status);
        throw new Error('Something went wrong');
      }

      return response.data;
    } catch (e: any) {
      console.log(e);

      if (e.response.data.errorMessage) {
        console.log('errorMessage');
        return rejectWithValue(e.response.data.errorMessage);
      }

      return rejectWithValue(e.response.statusText);
    }
  }
);

export const handleCheckUser = createAsyncThunk<ILoginResponse, string>(
  'auth/check',
  async (email, { rejectWithValue }) => {
    try {
      const response = await $api.post('check_email', { email: email });

      if (response.status !== 200) {
        throw new Error('Something went wrong');
      }

      return response.data;
    } catch (e: any) {
      if (e.response.data.errorMessage) {
        console.log('errorMessage');
        return rejectWithValue(e.response.data.errorMessage);
      }

      return rejectWithValue(e.response.statusText);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPaymentPlan(state, action) {
      state.paymentDetails.paymentPlan = action.payload;
    },
    logout(state) {
      console.log('doing logout...');
      state.isAuth = false;
      localStorage.removeItem('email');
    },
  },
  extraReducers(builder) {
    builder.addCase(handleRegister.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(handleRegister.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.email = action.payload.customer_email;
      state.paymentDetails.paymentLink = action.payload.payment_link;
      localStorage.setItem('email', action.payload.customer_email);
    });
    builder.addCase(handleRegister.rejected, (state, action) => {
      state.loading = false;

      //@ts-ignore
      state.error = action.payload;
      // state.error = 'Something went wrong';
    });

    builder.addCase(handleLogin.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.email = action.payload.customer_email;
      localStorage.setItem('email', action.payload.customer_email);
    });
    builder.addCase(handleLogin.rejected, (state, action) => {
      state.loading = false;
      //@ts-ignore
      state.error = action.payload;
      // console.log(`Error: ${action.payload}`);
      // state.error = 'Something went wrong';
    });

    builder.addCase(handleCheckUser.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(handleCheckUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.email = action.payload.customer_email;
    });
    builder.addCase(handleCheckUser.rejected, (state, action) => {
      state.isAuth = false;
      state.loading = false;
      localStorage.removeItem('email');
    });
  },
});

export default authSlice.reducer;
export const { setPaymentPlan, logout } = authSlice.actions;

import React, { FC } from 'react';
import classes from './Footer.module.css';
import Logo from '../../assets/images/adbuyLogo2024.svg';
import { Link } from 'react-router-dom';
import InstagramLogo from '../../assets/images/instagramIconFooter.png';
import TiktokLogo from '../../assets/images/tiktokIconFooter.png';
import XLogo from '../../assets/images/xIconFooter.png';
import LinkedinLogo from '../../assets/images/inIconFooter.png';
import { useMediaQuery } from 'react-responsive';

const Footer: FC = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 440px)',
  });

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.footerBlock}>
          <img src={Logo} className={classes.adbuyLogo} />
          <div className={classes.socialblock}>
          <a
                href="https://www.linkedin.com/company/adbuyme/"
                className={classes.socialButton}
            >
              <img src={LinkedinLogo} className={classes.inLogo} />
            </a>
            <a
              href="https://www.tiktok.com/@adbuy.me"
              className={classes.socialButton}
            >
              <img src={TiktokLogo} className={classes.logo} />
            </a>
            <a
                href="https://instagram.com/adbuy.me"
                className={classes.socialButton}
            >
              <img src={InstagramLogo} className={classes.instagramLogo} />
            </a>
            <a
              href="https://twitter.com/adbuy_me"
              className={classes.socialButton}
            >
              <img src={XLogo} className={classes.logo} />
            </a>
          </div>


        </div>

        {isMobile && <div className={classes.line}></div>}

        <div className={classes.footerInfo}>
          <div className={classes.footerButtons}>
            <Link className={classes.button} to={'/privacy'}>
              Privacy Policy
            </Link>

            <Link className={classes.button} to={'/refund'}>
              Refund Policy
            </Link>
          </div>

          <div className={classes.footerTextBlock}>
            <div>
              <p className={classes.footerText}>
                Copyright © 2024 Adbuy Inc. All rights reserved.
              </p>
            </div>

            <div className={classes.locContainer}>
              <p className={classes.footerLocText}>
                800 N King Street, Suite 304 1337, Wilmington, DE 19801
              </p>
            </div>

            <div className={classes.locContainer}>
              <p className={classes.footerLocText}>
                For support and refund-related inquiries, please
              </p>
            </div>

            <div className={classes.locContainer}>
              <p className={classes.footerLocText}>
                сontact us at support@adbuy.me or +1 937-551-5557
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*<p className={classes.footerTextSubscription}>*/}
      {/*  To cancel your subscription, please send your account email to ceo@adbuy.me.*/}
      {/*  <br/> You will receive an email confirming your cancellation within a few hours.*/}
      {/*</p>*/}

      {/*{isMobile ? (*/}
      {/*        <>*/}
      {/*          <p className={classes.footerTextSubscription}>*/}
      {/*            To cancel your subscription, please send your account email to ceo@adbuy.me.*/}
      {/*            You will receive an email confirming your cancellation within a few hours.*/}
      {/*          </p>*/}
      {/*        </>*/}
      {/*    ) :*/}
      {/*    (*/}
      {/*        <>*/}
      {/*          <p className={classes.footerTextSubscription}>*/}
      {/*            To cancel your subscription, please send your account email to ceo@adbuy.me.*/}
      {/*            <br /> You will receive an email confirming your cancellation within a few hours.*/}
      {/*          </p>*/}
      {/*        </>*/}
      {/*    )*/}
      {/*}*/}
    </footer>
  );
};

export default Footer;

import './App.css';
import AppRouter from './routes/AppRouter';
// import "primereact/resources/themes/nova-light/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { useEffect } from 'react';
import { useAppDispatch } from './hooks/useAppDispatch';
import { handleCheckUser } from './modules/AuthModule/store/authSlice';
import { useAppSelector } from './hooks/useAppSelector';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localStorage.getItem('email') !== null) {
      console.log('checking user');
      const email = localStorage.getItem('email');

      if (!email) {
        console.log('No email');
        return;
      }

      dispatch(handleCheckUser(email));
    } else {
      console.log('not auth from here');
    }
  }, []);

  // if (loading) {
  //   return null;
  // }

  return <AppRouter />;
}

export default App;

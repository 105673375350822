import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components';
import classes from './Layout.module.css';

const Layout: FC = () => {
  return (
    <div className={classes.layout}>
      <Header />

      <Outlet />
    </div>
  );
};

export default Layout;

import { FC, useState } from 'react';
import classes from './Header.module.css';
import { NavLink, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import logo from '../../assets/images/adbuyLogo2024.svg';
import UserIcon from '../../assets/images/icons8-user-96.png';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { logout } from '../../modules/AuthModule/store/authSlice';

const Header: FC = () => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isOpenNav, setOpenNav] = useState(false);

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery({
    query: '(max-width: 440px)',
  });

  const handleLogoutButton = () => {
    console.log('logout');
    dispatch(logout(undefined));
  };

  const handleOpenMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  return (
    <div className={classes.header}>
      <NavLink to={'/generate'}>
        <img className={classes.logo} src={logo} />
      </NavLink>
      {isMobile ? (
        <>
          <button
            onClick={() => setOpenNav((prev) => !prev)}
            className={classes.hamburger}
          >
            <div
              className={`${classes.hamburgerLine} ${
                isOpenNav ? classes.closeFirstLine : ''
              }`}
            ></div>
            <div
              className={`${classes.hamburgerLine} ${
                isOpenNav ? classes.closeSecondLine : ''
              }`}
            ></div>
            <div
              className={`${classes.hamburgerLine} ${
                isOpenNav ? classes.closeThirdLine : ''
              }`}
            ></div>
          </button>
          <div
            className={`${classes.mobileNav} ${isOpenNav ? classes.open : ''}`}
          >
            <ul className={classes.mobileNavList}>
              <Link
                onClick={() => setOpenNav(false)}
                to={'/generate'}
                className={classes.mobileNavLink}
              >
                Home
              </Link>
              <Link
                onClick={() => setOpenNav(false)}
                to={'/generate'}
                className={classes.mobileNavLink}
              >
                Generate
              </Link>
              <Link
                onClick={() => setOpenNav(false)}
                to={'/creatives'}
                className={classes.mobileNavLink}
              >
                Creatives
              </Link>
              <Link
                onClick={() => setOpenNav(false)}
                to={'campaigns'}
                className={classes.mobileNavLink}
              >
                Campaigns
              </Link>
            </ul>
            <div className={classes.mobileLoginBlock}>
              <a className={classes.profileBlockLink}>Subscriptions</a>
              <a
                onClick={handleLogoutButton}
                className={classes.profileBlockLink}
              >
                Logout
              </a>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes.headerLinkBlock}>
            <NavLink
              className={({ isActive }) =>
                `${classes.headerLink} ${isActive ? classes.activeLink : null}`
              }
              to={'/generate'}
            >
              Generate
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `${classes.headerLink} ${isActive ? classes.activeLink : null}`
              }
              to={'/creatives'}
            >
              Creatives
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `${classes.headerLink} ${isActive ? classes.activeLink : null}`
              }
              to={'/campaigns'}
            >
              Campaigns
            </NavLink>
          </div>
          <div className={classes.headerProfileBlock}>
            <button onClick={handleOpenMenu} className={classes.profileButton}>
              <img className={classes.profileImage} src={UserIcon} />
            </button>
            <div
              className={`${classes.profileBlock} ${
                isOpenMenu ? classes.openBlock : ''
              }`}
            >
              <a className={classes.profileBlockLink}>Subscriptions</a>
              <a
                onClick={handleLogoutButton}
                className={classes.profileBlockLink}
              >
                Logout
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;

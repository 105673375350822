import React from 'react';
import { Loader } from '../../ui';

const ScreenLoader = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Loader />
    </div>
  );
};

export default ScreenLoader;

import { configureStore } from '@reduxjs/toolkit';
import adReducer from './slices/adSlice';
import authSlice from '../modules/AuthModule/store/authSlice';

export const store = configureStore({
  reducer: {
    ad: adReducer,
    auth: authSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
